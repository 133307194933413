import React from 'react';
import './connectModal.scss'
import PropTypes from 'prop-types';

const ConnectModal = ({ isOpen, onClose, children }) => {

  if (!isOpen) return null;

  return (
    // <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" style={{width: '50%', margin: 'auto'}}>
    //   <div className="bg-dark rounded-lg shadow-lg p-6 w-full max-w-md relative">
    //     <button 
    //       className="absolute top-2 right-2 text-gray-500" 
    //       onClick={onClose}
    //     >
    //       &times;
    //     </button>
    <div  className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" style={{width: '50%', justifyContent: 'center', margin: 'auto'}}>
      <div className="bg-dark rounded-lg shadow-lg p-6 w-full max-w-md relative">
        {children}
    </div>
    </div>
    //   </div>
    // </div>
  );
}

ConnectModal.prototype = {
    isOpen: PropTypes.boolean,
    onClose: PropTypes.boolean,
    children: PropTypes.node
}

export default ConnectModal