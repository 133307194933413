// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { Switch } from 'react-router-dom/Switch';
// import Main from './pages/main/Main';
import Header from "./pages/header/Header";
// import Market from './pages/markeet/Market';
// import Pools from './pages/pools/pool';
// import Connect from './pages/connect/Connect';
// import Page1 from './pages/main/Page1';
// import Lend from './pages/main/Lend';
// import Stake from './pages/main/Stake';
// import Bridge from './pages/main/Bridge';
// import Extra from './pages/main/Extra';
// import DeBridge from './pages/main/DeBridge';
import HeaderList from "./pages/header/HeaderList";
import Restake from "./pages/main/Restake";
import Stakes from "./pages/main/Stakes";
import Portfolio from "./pages/main/Portfolio";
import EigenLayer from "./pages/main/EigenLayer";
import Withdrawal from "./pages/main/Withdrawal";
import Operator from "./pages/main/Operator";
import Operators from "./pages/main/Operators";
import Analytics from "./pages/main/Analytics";
import Defi from "./pages/main/Defi";
import ConnectWallet from "./pages/header/ConnectWallet";
import HomePage from "./pages/main/HomePage";
import Dicover from "./pages/main/Discover";
function App() {
  return (
    <BrowserRouter>
      <div className="App">
        {/* <Navbar /> */}

        {/* <div className="flex flex-col items-center justify-center visible-nav:p-4">
          <div className="dark:bg-fg-dark visible-nav:h-[calc(100vh_-_32px)] visible-nav:rounded-2xl flex h-svh w-full flex-row items-start justify-start overflow-hidden">
            <HeaderList />
            <div className="visible-nav:opacity-100 visible-nav:w-[calc(100vw-240px)] z-0 flex size-full flex-col items-start justify-start transition-opacity">
              <Header /> */}

        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/rewards" element={<Restake />} />
          {/* <Route exact path="/discover" element={<Dicover />} />
                <Route  path="/restake" element={<Restake />} />
               <Route  path="/stake" element={<Stakes />} />
               <Route  path="/portfolio" element={<Portfolio />} />
               <Route  path="/eigenlayer" element={<EigenLayer />} />
               <Route  path="/withdrawals" element={<Withdrawal />} />
               <Route  path="/referral" element={<Operator />} />
               <Route  path="/operators" element={<Operators />} />
               <Route  path="/analytics" element={<Analytics />} />
               <Route  path="/defi" element={<Defi />} />
               <Route  path="/connect" element={<ConnectWallet />} /> */}
          {/* <Route  path="/lend" element={<Lend />} />
          <Route  path="/staking" element={<Stake />} />
          <Route  path="/bridge" element={<Bridge />} />
          <Route  path="/bridge/extra" element={<Bridge />} />
          <Route  path="/bridge/lifi" element={<Extra />} />  */}
          {/** holds Lifi data */}
          {/* <Route  path="/bridge/debridge" element={<DeBridge />} /> */}
          {/* <Route  path="/bridge/debridge" element={<Extra />} />*/}
          <Route path="*" element={<Restake to="/" />} />
        </Routes>
        {/* <Footer /> */}
        {/* </div>
          </div>
        </div> */}
      </div>
    </BrowserRouter>
  );
}

export default App;
